<template>
    <div>
        <div v-if="showPopup" id="consent-container-background">
            <div id="consent-container">
                <div v-if="state === 0">
                    <div class="consent-header text-center">Diese Webseite verwendet Cookies</div>
                    <div style="font-size: 12px; padding: 10px 60px; text-align: left">
                        Es werden von uns keine Nutzerdaten an Dritte weitergegeben, weder zur
                        Analyse noch zu Werbezwecken. Datenschutz und -privatsphäre ist uns ein
                        großes Anliegen weshalb wir auf alle Dienstleistungen von Datenkraken
                        verzichten und alle Daten nur auf unseren eigenen Servern speichern.
                        <br />
                        <br />
                        Wir verwenden technisch notwendige Cookies damit die Seite korrekt
                        dargestellt werden kann. Des Weiteren verwenden wir Cookies um das
                        Nutzerverhalten zu analysieren damit wir die Seite stetig verbessern können.
                        <br />
                        <br />
                        Sie können ausführliche Informationen dazu in unserer Datenschutzerklärung
                        finden. Diese finden Sie in der Fußzeile der Webseite, dort können Sie Ihre
                        Zustimmung einsehen und gegebenenfalls widerrufen.
                    </div>
                    <div class="consent-button-accept text-center" @click="selectAllCookies">
                        Cookies zulassen
                    </div>
                    <div class="consent-button-select text-center" @click="toCookieSelectState">
                        Auswahl der Cookies
                    </div>
                    <div class="text-center">
                        <router-link class="link-privacy" to="datenschutz"
                            >Datenschutzerklärung</router-link
                        >
                    </div>
                </div>

                <div v-else-if="state === 1">
                    <div class="consent-header text-center">Auswahl der Cookies</div>
                    <div class="text-center" style="font-size: 12px">
                        Bitte wählen Sie Ihre Cookie-Präferenzen:
                    </div>
                    <div style="text-align: left; padding: 0 20px; margin-top: 20px">
                        <div style="font-size: 16px; font-weight: bold">
                            <div style="padding-left: 25px; position: relative">
                                Funktionale Cookies
                                <input
                                    id="consent-check-2"
                                    v-model="checkType2"
                                    type="checkbox"
                                    style="position: absolute; left: 0; top: 3px"
                                />
                            </div>
                        </div>
                        <div style="padding-left: 25px; padding-right: 25px; font-size: 14px">
                            Wir verwenden funktionale Cookies um das Nutzerverhalten zu analysieren
                            damit wir die Seite stetig verbessern können. Alle erfassten Nutzerdaten
                            werden ausschließlich auf unseren eigenen Servern gespeichert.
                        </div>
                    </div>
                    <div style="text-align: left; padding: 0 20px; margin-top: 15px">
                        <div style="font-size: 16px; font-weight: bold">
                            <div style="padding-left: 25px; position: relative">
                                Benötigte Cookies
                                <input
                                    id="consent-check-1"
                                    v-model="checkType1"
                                    type="checkbox"
                                    disabled
                                    style="position: absolute; left: 0; top: 3px"
                                />
                            </div>
                        </div>
                        <div style="padding-left: 25px; padding-right: 25px; font-size: 14px">
                            Diese Cookies sind notwendig damit die Webseite ordnungsgemäß
                            funktioniert. Das Deaktivieren dieser Einstellung ist nicht möglich,
                            denn sonst kann die Seite nicht mehr angezeigt werden.
                        </div>
                    </div>
                    <div
                        class="consent-button-accept text-center"
                        style="margin-top: 50px"
                        @click="selectCustomCookies"
                    >
                        Ausgewählte Cookies zulassen
                    </div>
                    <div class="text-center">
                        <router-link class="link-privacy" to="datenschutz"
                            >Datenschutzerklärung</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CookieConsent',
    data() {
        return {
            showPopup: true,
            state: 0,
            checkType1: true,
            checkType2: false,
        }
    },
    created() {
        const routeName = this.$route.name
        if (routeName === 'datenschutz') {
            this.showPopup = false
            return
        }
        this.checkConsentCookie()
    },
    methods: {
        toCookieSelectState() {
            this.state = 1
        },
        selectAllCookies() {
            this.checkType2 = true
            this.setConsentCookie()
            this.showPopup = false
        },
        selectCustomCookies() {
            this.setConsentCookie()
            this.showPopup = false
        },
        checkConsentCookie() {
            let c = this.$cookies.get(this.$CONSENT)

            if (c) {
                if (c.checkType2) {
                    this.checkType2 = c.checkType2
                } else {
                    this.checkType2 = false
                }
                this.showPopup = false
            } else {
                this.showPopup = true
            }
        },
        setConsentCookie() {
            let cookieState = {
                checkType2: this.checkType2,
            }
            this.$cookies.set(this.$CONSENT, cookieState, '7d', '/', undefined, true, 'Strict')
        },
    },
}
</script>

<style>
#consent-container-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1998;
}

#consent-container {
    width: 80%;
    max-height: 90%;
    overflow-y: auto;
    position: fixed;
    background-color: white;
    z-index: 1999;
    margin-top: 10%;
    margin-left: 10%;
    padding-top: 10px;
    border-radius: 6px;
}
.consent-button-accept {
    background-color: rgb(13, 85, 165);
    color: white;
    border-radius: 6px;
    min-height: 40px;
    margin: 10px 40px;
    vertical-align: middle;
    cursor: pointer;
    padding-top: 7px;
}
.consent-button-select {
    border: 1px solid rgb(13, 85, 165);
    color: rgb(13, 85, 165);
    border-radius: 6px;
    min-height: 40px;
    margin: 10px 40px 5px 40px;
    cursor: pointer;
    padding-top: 7px;
}

.consent-header {
    font-size: 22px;
}
.link-privacy {
    font-size: 12px;
    text-decoration: underline;
}

@media only screen and (min-width: 600px) {
    #consent-container {
        width: 600px;
        height: 420px;
        position: fixed;
        background-color: white;
        z-index: 1999;
        margin-top: 10%;
        margin-left: calc(50% - 300px);
        padding-top: 10px;
        border-radius: 6px;
    }
    .consent-button-accept {
        background-color: rgb(13, 85, 165);
        color: white;
        border-radius: 6px;
        height: 40px;
        margin: 10px 40px;
        vertical-align: middle;
        cursor: pointer;
        padding-top: 7px;
    }
    .consent-button-select {
        border: 1px solid rgb(13, 85, 165);
        color: rgb(13, 85, 165);
        border-radius: 6px;
        height: 40px;
        margin: 10px 40px 5px 40px;
        cursor: pointer;
        padding-top: 7px;
    }
}
</style>