<template>
    <div class="relative">
        <div id="hero-image" class="hero-image bg-no-repeat bg-center lg:bg-center bg-cover">
            <div
                id="hero-image-large"
                class="hero-image bg-no-repeat bg-center lg:bg-center bg-cover"
            ></div>
        </div>

        <div
            id="hero-text-container"
            class="flex flex-col absolute top-36 left-1/2 transform -translate-x-1/2 w-auto text-left sm:left-5 sm:translate-x-0 sm:top-30 md:top-30 xl:left-own bg-white p-10 rounded-30"
        >
            <h1 class="text-white text-3xl md:text-4xl xl:text-5xl mb-4">
                <img src="@/assets/svg/cybo-logo_style2_white.svg" alt="" class="h-20" />
                das digitale Kassenbonsystem
                <br />
                für einfaches, umweltbewusstes
                <br />
                und sicheres Quittieren
            </h1>

            <div class="flex sm:flex-row flex-col">
                <CtaBtn routing="produktinformation" styling="secondary">Unser Produkt</CtaBtn>
                <CtaBtn routing="produktanfrage" styling="secondary">Jetzt anfragen</CtaBtn>
            </div>
        </div>
    </div>
</template>

<script>
import CtaBtn from '@/components/UI/CtaBtn'

export default {
    name: 'HeroPrimary',
    components: { CtaBtn },
}
</script>

<style scoped>
.hero-image {
    min-height: 742px;
}
#hero-image {
    /*background-color: #1d1f24;*/
    background-image: url('~@/assets/images/header_background_cybo.png');
}
#hero-image-large {
    background-color: transparent;
    background-image: url('../assets/images/header_background_cybo.png');
}
#hero-text-container {
    background: linear-gradient(141deg, #4f98ff, #00a6fb 46.44%, #5692ff 97.88%);
    box-shadow: 0 0 40px 18px #84e4ff94;
}
</style>
