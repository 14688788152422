<template>
    <div>
        <!--Kontakt Container-->
        <ContentLayoutContact>
            <!--Left-->
            <section v-if="state === 0" class="sm:w-4/5 md:w-3/5 lg:w-1/2 px-8 mx-auto pb-6">
                <!--Kontakt Wrapper-->

                <PageHeadline class="text-left">
                    Falls Sie weitere Fragen zu unserem Produkt haben, zögern Sie nicht uns zu
                    schreiben!</PageHeadline
                >

                <PageSubHeadline class="text-left text-2xl">
                    Ihr <span class="text-BS002E62">cybo</span>-Team
                </PageSubHeadline>

                <form
                    id="contact-form"
                    class="flex flex-col w-full mt-12 shadow-custom"
                    @submit.prevent="sendContactRequest"
                >
                    <label class="hidden" for="concern">Ihr Anliegen</label>
                    <textarea
                        id="concern"
                        v-model="message"
                        class="textarea w-full h-130 rounded-t-6 p-2"
                        placeholder="Ihr Anliegen*"
                        required
                    ></textarea>

                    <div class="w-full text-center rounded-b-6 bg-white text-BS002E62">
                        <div class="px-4 pt-8">
                            <label class="hidden text-BS002E62" for="name">Name</label>
                            <input
                                id="name"
                                v-model="name"
                                class="field w-full bg-white h-8 text-BS002E62"
                                type="text"
                                name="name"
                                placeholder="Name*"
                                required
                            />
                        </div>
                        <div class="px-4 pt-8">
                            <label class="hidden" for="email">Email</label>
                            <input
                                id="email"
                                v-model="email"
                                class="field w-full bg-white h-8 text-BS002E62"
                                type="text"
                                name="email"
                                placeholder="Email*"
                                required
                            />
                        </div>
                        <div class="px-4 pt-8">
                            <label class="hidden" for="tel">Telefonummer</label>
                            <input
                                id="tel"
                                v-model="phone"
                                class="field w-full bg-white h-8 text-BS002E62"
                                type="tel"
                                name="tel"
                                placeholder="Telefonnummer"
                            />
                        </div>

                        <button
                            type="submit"
                            form="contact-form"
                            class="w-auto rounded-30 bg-BS002E62 px-10 py-2 my-4 text-g cursor-pointer hover:bg-primary duration-200"
                        >
                            <div v-if="isLoading">
                                <svg
                                    class="animate-spin h-5 w-5 text-white mx-auto"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        class="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        stroke-width="4"
                                    ></circle>
                                    <path
                                        class="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            </div>
                            <div v-else>Senden</div>
                        </button>
                    </div>
                </form>
            </section>

            <section v-else-if="state === 1" class="sm:w-4/5 md:w-3/5 lg:w-1/2 px-8 mx-auto pb-6">
                <PageHeadline class="text-left"> Vielen Dank für Ihre Anfrage! </PageHeadline>
                <PageSubHeadline class="text-left text-xl">
                    Wir werden uns zeitnah bei Ihnen melden.
                    <br />
                    <br />
                    Ihr <span class="text-primary">cybo</span> Team
                </PageSubHeadline>
            </section>

            <section v-else class="sm:w-4/5 md:w-3/5 lg:w-1/2 px-8 mx-auto pb-6">
                <PageHeadline class="text-left">
                    Ups!
                    <br />
                    Leider ist ein Fehler aufgetreten.
                </PageHeadline>
                <PageSubHeadline class="text-left text-lg">
                    Bitte versuchen Sie es später noch einmal oder schreiben Sie uns direkt unter
                    <a href="mailto:info@cybo.de" class="text-primary hover:underline"
                        >info@cybo.de</a
                    >
                </PageSubHeadline>
            </section>

            <!--Right-->
            <section class="hidden lg:block w-1/2">
                <img class="right" src="@/assets/images/Kontakt_web.jpg" alt="System on Counter" />
            </section>
        </ContentLayoutContact>
    </div>
</template>

<script>
import PageSubHeadline from '@/components/Layout/PageSubHeadline'
import PageHeadline from '@/components/Layout/PageHeadline'
import ContentLayoutContact from '@/components/Layout/ContentLayoutContact'
export default {
    name: 'KontaktPage',
    components: { ContentLayoutContact, PageSubHeadline, PageHeadline },
    meta() {
        return {
            title: 'Kontakt',
        }
    },
    data() {
        return {
            state: 0,
            isLoading: false,
            name: '',
            email: '',
            phone: '',
            message: '',
        }
    },
    methods: {
        sendContactRequest: function () {
            this.isLoading = true

            let context = this
            this.$http
                .post('/website/contactBasic', {
                    name: context.name,
                    email: context.email,
                    phone: context.phone,
                    message: context.message,
                })
                .then(function () {
                    context.state = 1
                    context.isLoading = false
                    context.name = ''
                    context.email = ''
                    context.phone = ''
                    context.message = ''
                })
                .catch(function (err) {
                    context.state = 2
                    context.isLoading = false
                    console.log(err)
                })
        },
    },
}
</script>

<style scoped>
* {
    outline: none;
}
.right {
    /*104 = Height of Form section*/
    min-height: 602px;
    /*104 = Height of NAV + FOOTER*/
    height: calc(100vh - 104px);
    width: 100% !important;
    object-fit: cover;
    object-position: bottom center;
}
.field {
    position: relative;
    border-bottom: 1px solid theme('colors.gray4');
    border-radius: 0;
}
.field:focus {
    border-bottom: 1px solid theme('colors.primary');
}
.field::placeholder {
    /*color: theme('colors.gray9');*/
    color: #002e62;
}
textarea::placeholder {
    color: theme('colors.background');
}
</style>
