export const faq1 = [
    {
        id: 1,
        titel: 'Ist cybo mit meinem Kassensystem kompatibel?',
        description:
            'Ja, wenn Sie eine Kasse mit Netzwerkanschluss, Internet und eine externen Thermobondrucker haben.',
    },

    {
        id: 2,
        titel: 'Erfüllt cybo die Belegausgabepflicht?',
        description: 'Ja.',
    },
    {
        id: 3,
        titel: 'Gibt es eine Mindestvertragslaufzeit?',
        description: 'Nein, cybo ist allerdings günstiger bei jährlicher Abrechnung.',
    },
    {
        id: 4,
        titel: 'Gibt es ein monatliches Limit für die Anzahl der mit cybo erstellten Kassenbelege?',
        description:
            'Nein. In der monatlichen Gebühr steht Ihnen der cybo-Service unbegrenzt zur Verfügung.',
    },
    {
        id: 5,
        titel: 'Woher bekomme ich das cybo-System?',
        description:
            'Das cybo-System wird von ihrem Kassenhändler oder einem unserer Fachhandelspartner angeboten und installiert. ' +
            'Gerne können Sie uns <a class="routing underline" href="/produktanfrage">hier</a> direkt anfragen',
    },
    {
        id: 6,
        titel: 'Mit welchen Kosten muss ich rechnen?',
        description:
            'Einmalige Anschaffungskosten für das cybo-System betragen 249 €<sup class="text-gray3">*</sup> + ' +
            'Installationsgebühren des Fachhandelspartners.<br> Der monatliche cybo-Service kostet 16,90 €<sup class="text-gray3">*</sup> ' +
            'bei jährlicher Abrechnung Genau informationen finden sie auf <a class="routing underline" href="/preise-und-vertrieb">' +
            'Preise und Vertrieb</a>.<br><span class="text-sm text-gray3">*Nettopreise zzgl. der gesetzlichen Mehrwertsteuer.</span>',
    },
    {
        id: 7,
        titel: 'Ist eine App oder Anmeldung erforderlich?',
        description:
            'Die Endkunden brauchen keine App oder Anmeldung um Ihre Belege digital zu erhalten.' +
            '<br>Die cybo-Kunden benötigen einen Account um das cybo-System und den cybo-Service zu verwalten.',
    },
    {
        id: 8,
        titel: 'Kann ich ältere Belege noch einmal einsehen?',
        description: 'Ja, Ihre Belege werden online in Ihrem Account gespeichert.',
    },
    {
        id: 9,
        titel: 'Ist eine Internetverbindung notwendig?',
        description: 'Ja.',
    },
    {
        id: 10,
        titel: 'Wie kann das cybo-System an die Kasse angeschlossen werden?',
        description: 'Die Kasse wird über ein Netzwerk mit dem cybo-System verbunden.',
    },
    {
        id: 11,
        titel: 'Kann der Beleg weiterhin ausgedruckt werden?',
        description:
            'Ja, es steht dem Endkunden frei per Knopfdruck einen Thermobeleg auszudrucken. <br> Ja, das Kassensystem kann so konfiguriert werden, dass der Bediener den Beleg drucken oder digital anbieten kann.',
    },
]
export const faq2 = [
    {
        id: 12,
        titel: 'Kann ich cybo mit mobilem Internet betreiben, zum Beispiel in einem Foodtruck?',
        description: 'Ja, der cybo-Service benötigt nur ein geringes Datenvolumen.',
    },
    {
        id: 13,
        titel: 'Wie viel Aufwand ist es cybo anzuschließen?',
        description:
            'In der Regel dauert die Einrichtung von cybo so lange wie die Einrichtung eines neuen Druckers (Wenn die Voraussetzungen bereits erfüllt sind) <br> Voraussetzungen: lokales Kassennetzwerk, Internetzugang, externer Bondrucker.',
    },
    {
        id: 14,
        titel: 'Kann ich mehr als eine Kasse an das cybo-System anschließen?',
        description: 'Nein, pro Kassenplatz wird ein cybo-System benötigt.',
    },
]
export const faq3 = [
    {
        id: 15,
        titel: 'Werden personenbezogene Daten gespeichert?',
        description:
            'Nein, es wird nur der Kassenbeleg hochgeladen und für eine bestimmte Zeit gespeichert.',
    },
    {
        id: 16,
        titel: 'Besteht die Möglichkeit meine Belege dauerhaft zu archivieren?',
        description:
            'Ja, es gibt die Möglichkeit sie online konstenlos in Ihrem Account abzuspeichern.',
    },
    {
        id: 17,
        titel: 'Ist cybo DSGVO konform?',
        description: 'Ja, es werden keine persöhnlichen Daten gespeichert oder hochgeladen.',
    },
    {
        id: 18,
        titel: 'Brauche ich eine App oder einen Account um cybo zu nutzen?',
        description: 'Nein, es wird weder eine App noch ein Account benötigt, um cybo zu nutzen.',
    },
    {
        id: 19,
        titel: 'Mit welchen Handys kann ich cybo nutzen?',
        description:
            'Das Handy muss entweder eine QR-Code-fähige Kamera haben oder Nfc-fähig sein.',
    },
]
