<template>
    <ContentLayout>
        <PageHeadline>Datenschutzerklärung</PageHeadline>
        <div class="text-left text-gray7">
            <section>
                <ContentH2>1. Datenschutz auf einen Blick</ContentH2>

                <ContentH3>Allgemeine Hinweise</ContentH3>
                <p>
                    Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren
                    personenbezogenen Daten passiert, wenn Sie diese Webseite besuchen.
                    Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert
                    werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie
                    unserer unter diesem Text aufgeführten Datenschutzerklärung.
                </p>

                <ContentH3
                    >Wer ist verantwortlich für die Datenerfassung auf dieser Webseite?</ContentH3
                >
                <p>
                    Die Datenverarbeitung auf dieser Website erfolgt ausschließlich durch den
                    Webseitenbetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur
                    Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
                </p>

                <ContentH3>Wie erfassen wir Ihre Daten?</ContentH3>
                <p>
                    Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen.
                    Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular
                    eingeben.
                </p>
                <p>
                    Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der
                    Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.
                    B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
                    Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Webseite betreten
                    und Ihre Einwilligung erteilt haben.
                </p>

                <ContentH3>Wofür nutzen wir Ihre Daten?</ContentH3>
                <p>
                    Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Webseite
                    zu gewährleisten. Dazu fallen beispielsweise die Dimensionen des Bildschirms
                    Ihres Anzeigegeräts. Andere Daten, zum Beispiel Ihr Nutzerverhalten, können zur
                    Verbesserung der Webseite verwendet werden. Daten die Sie uns über ein
                    Kontakformular mitteilen, werden ausschließlich zur Kontaktaufnahme mit Ihnen
                    benutzt.
                </p>
                <p>Es werden keinerlei Daten an Dritte weitergegeben.</p>

                <ContentH3>Welche Rechte haben Sie bezüglich Ihrer Daten?</ContentH3>
                <p>
                    Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger
                    und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben
                    außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen.
                    Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese
                    Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
                    unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
                    personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
                    Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                </p>
                <p>
                    Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit
                    an uns wenden.
                </p>
            </section>
            <!-- SECOND PART -->
            <section class="mt-10">
                <ContentH2>2. Allgemeine Hinweise und Pflichtinformationen</ContentH2>

                <ContentH3>Datenschutz</ContentH3>
                <p>
                    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr
                    ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend
                    der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                    <br />
                    Wenn Sie diese Webseite benutzen, werden verschiedene personenbezogene Daten
                    erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
                    identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert,
                    welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und
                    zu welchem Zweck das geschieht.
                    <br />
                    Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der
                    Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser
                    Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
                </p>

                <ContentH3>Hinweis zur verantwortlichen Stelle</ContentH3>
                <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Webseite ist:</p>
                <ul>
                    <li>NovaNode GmbH</li>
                    <li>Dresdnerstraße 44</li>
                    <li>63110 Rodgau</li>
                </ul>
                <p>
                    Verantwortliche Stelle ist die natürliche oder juristische Person, die allein
                    oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von
                    personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
                </p>

                <ContentH3>Speicherdauer</ContentH3>
                <p>
                    Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer
                    genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck
                    für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen
                    geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden
                    Ihre Daten gelöscht, sofern wir keinen anderen rechtlich zulässigen Gründe für
                    die Speicherung Ihrer personenbezogenen Daten haben (z.B. steuer- oder
                    handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die
                    Löschung nach Fortfall dieser Gründe.
                </p>

                <ContentH3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</ContentH3>
                <p>
                    Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung
                    möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die
                    Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
                    Widerruf unberührt.
                </p>

                <ContentH3
                    >Widerspruchsrecht gegen die Datenerhebung in besonderen Fälle sowie gegen
                    Direktwerbung (Art. 21 DSGVO)</ContentH3
                >
                <p>
                    Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f DSGVO
                    erfolgt, haben Sie jederzeit das Recht, aus Gründen, die sich aus Ihrer
                    besonderen Situation ergeben, gegen die Verarbeitung Ihrer personenbezogenen
                    Daten Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen
                    gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf denen einen
                    Verarbeitung beruht, entnehmen Sie dieser Datenschutzerklärung. Wenn Sie
                    Widerspruch einlegen, werden wir Ihre betroffenen personenbezogenen Daten nicht
                    mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die
                    Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen
                    oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
                    Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 2 DSGVO).
                </p>

                <ContentH3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</ContentH3>
                <p>
                    Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht
                    bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen
                    Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu.
                    Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher
                    oder gerichtlicher Rechtsbehelfe.
                </p>

                <ContentH3>Recht auf Datenübertragbarkeit</ContentH3>
                <p>
                    Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in
                    Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen
                    Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen.
                    Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
                    verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                </p>

                <ContentH3>SSL- bzw. TLS-Verschlüsselung</ContentH3>
                <p>
                    Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
                    vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an
                    uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine
                    verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers
                    von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer
                    Browserzeile.
                    <br />
                    Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie
                    an uns übermitteln, nicht von Dritten mitgelesen werden.
                </p>

                <ContentH3>Auskunft, Löschung und Berichtigung</ContentH3>
                <p>
                    Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht
                    auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten,
                    deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein
                    Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
                    Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
                </p>

                <ContentH3>Recht auf Einschränkung der Verarbeitung</ContentH3>
                <p>
                    Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
                    Daten zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht
                    auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
                </p>
                <ul class="my-4 ml-4 list-disc">
                    <li class="my-4">
                        Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten
                        bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die
                        Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung
                        Ihrer personenbezogenen Daten zu verlangen.
                    </li>

                    <li class="my-4">
                        Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
                        geschah/geschieht, können Sie statt der Löschung die Einschränkung der
                        Datenverarbeitung verlangen.
                    </li>

                    <li class="my-4">
                        Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch
                        zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen
                        benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der
                        Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                    </li>

                    <li class="my-4">
                        Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss
                        eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden.
                        Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das
                        Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
                        verlangen.
                    </li>
                </ul>
                <p>
                    Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben,
                    dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer
                    Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von
                    Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
                    juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der
                    Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
                </p>

                <ContentH3>Widerspruch gegen Werbe-E-Mail</ContentH3>
                <p>
                    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten
                    zur Übersendung von nicht ausdrücklich angeforderter Werbung und
                    Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten
                    behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten
                    Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
                </p>
            </section>
            <!-- THIRD PART -->
            <section class="mt-10">
                <ContentH2>3. Datenerfassung auf dieser Webseite</ContentH2>

                <ContentH3>Cookies</ContentH3>
                <p>
                    Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine
                    Textdateien und richten auf Ihrem Endgerät keinen Schaden an. Sie werden
                    entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder
                    dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies
                    werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben
                    auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine
                    automatische Löschung durch Ihren Webbrowser erfolgt.
                </p>
                <p>
                    Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät
                    gespeichert werden, wenn Sie unsere Seite betreten (Third-Party-Cookies). Diese
                    ermöglichen uns oder Ihnen die Nutzung bestimmter Dienstleistungen des
                    Drittunternehmens (z.B. Cookies zur Abwicklung von Zahlungsdienstleistungen).
                </p>
                <p>
                    Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch
                    notwendig, da bestimmte Webseitenfunktionen ohne diese nicht funktionieren
                    würden (z.B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies
                    dienen dazu, das Nutzerverhalten auszuwerten oder Werbung anzuzeigen.
                </p>
                <p>
                    Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs
                    (notwendige Cookies) oder zur Bereitstellung bestimmter, von Ihnen erwünschter
                    Funktionen (funktionale Cookies, z. B. für die Warenkorbfunktion) oder zur
                    Optimierung der Website (z.B. Cookies zur Messung des Webpublikums) erforderlich
                    sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern
                    keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
                    berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien
                    und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur
                    Speicherung von Cookies abgefragt wurde, erfolgt die Speicherung der
                    betreffenden Cookies ausschließlich auf Grundlage dieser Einwilligung (Art. 6
                    Abs. 1 lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar.
                </p>
                <p>
                    Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies
                    informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von
                    Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische
                    Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der
                    Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt
                    sein.
                </p>
                <p>
                    Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden,
                    werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung gesondert
                    informieren und ggf. eine Einwilligung abfragen.
                </p>

                <ContentH3>Server-Log-Dateien</ContentH3>
                <p>
                    Der Provider der Seiten erhebt und speichert automatisch Informationen in so
                    genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt.
                </p>
                <p>Dies sind:</p>
                <ul class="my-4 ml-5 list-disc">
                    <li class="my-2">Browsertyp und Browserversion</li>
                    <li class="my-2">verwendetes Betriebssystem</li>
                    <li class="my-2">Referrer URL</li>
                    <li class="my-2">Uhrzeit der Serveranfrage</li>
                    <li class="my-2">IP-Adresse</li>
                </ul>

                <p>
                    Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
                    vorgenommen.
                </p>
                <p>
                    Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
                    Der Websitebetreiber hat ein berechtigtes Interesse an der technisch
                    fehlerfreien Darstellung und der Optimierung seiner Website – hierzu müssen die
                    Server-Log-Files erfasst werden.
                </p>

                <ContentH3>Kontaktformular</ContentH3>
                <p>
                    Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben
                    aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten
                    zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns
                    gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                </p>
                <p>
                    Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b
                    DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder
                    zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen
                    Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der
                    effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
                    DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
                    abgefragt wurde.
                </p>
                <p>
                    Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie
                    uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder
                    der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener
                    Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere
                    Aufbewahrungsfristen – bleiben unberührt.
                </p>

                <ContentH3>Anfrage per E-Mail, Telefon oder Telefax</ContentH3>
                <p>
                    Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage
                    inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage)
                    zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet.
                    Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                </p>
                <p>
                    Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b
                    DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder
                    zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen
                    Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der
                    effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
                    DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
                    abgefragt wurde.
                </p>
                <p>
                    Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns,
                    bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
                    widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach
                    abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen
                    – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
                </p>
            </section>
            <!-- FOURTH PART -->
            <section class="mt-10">
                <ContentH2>4. Analyse-Tools und Werbung</ContentH2>

                <ContentH3>Matomo (ehemals Piwik)</ContentH3>
                <p>
                    Diese Webseite benutzt den Open Source Webanalysedienst Matomo. Matomo verwendet
                    Technologien, die die seitenübergreifende Wiedererkennung des Nutzers zur
                    Analyse des Nutzerverhaltens ermöglichen (z.B. Cookies oder
                    Device-Fingerprinting). Die durch Matomo erfassten Informationen über die
                    Benutzung dieser Webseite werden auf unserem Server gespeichert. Die IP-Adresse
                    wird vor der Speicherung anonymisiert.
                </p>
                <p>
                    Mit Hilfe von Matomo sind wir in der Lage Daten über die Nutzung unserer Website
                    durch die Websitebesucher zu erfassen und zu analysieren. Hierdurch können wir
                    u. a. herausfinden, wann welche Seitenaufrufe getätigt wurden und aus welcher
                    Region sie kommen. Außerdem erfassen wir verschiedene Logdateien (z.B.
                    IP-Adresse, Referrer, verwendete Browser und Betriebssysteme) und können messen,
                    ob unsere Websitebesucher bestimmte Aktionen durchführen.
                </p>
                <p>
                    Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
                    DSGVO. Der Webseitenbetreiber hat ein berechtigtes Interesse an der
                    anonymisierten Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch
                    seine Werbung zu optimieren. Sofern eine entsprechende Einwilligung abgefragt
                    wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt die
                    Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
                    Einwilligung ist jederzeit widerrufbar.
                </p>
                <p>
                    Wir hosten Matomo ausschließlich auf unseren eigenen Servern, sodass alle
                    Analysedaten bei uns verbleiben und nicht an Dritte weitergegeben werden.
                </p>
            </section>
            <!-- FIFTH PART -->
            <section class="mt-10">
                <ContentH2>5. Newsletter</ContentH2>
                <ContentH3>Newsletterdaten</ContentH3>
                <p>
                    Wenn Sie den auf der Webseite angebotenen Newsletter beziehen möchten, benötigen
                    wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die
                    Überprüfung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind
                    und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden
                    nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir
                    ausschließlich für den Versand der angeforderten Informationen und geben diese
                    nicht an Dritte weiter.
                </p>
                <p>
                    Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt
                    ausschließlich auf Grunxdlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).
                    Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie
                    deren Nutzung zum Versand des Newsletters können Sie jederzeit widerrufen, etwa
                    über den „Austragen“-Link im Newsletter. Die Rechtmäßigkeit der bereits
                    erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
                </p>
                <p>
                    Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden
                    von uns bis zu Ihrer Austragung aus dem Newsletter bei uns bzw. dem
                    Newsletterdiensteanbieter gespeichert und nach der Abbestellung des Newsletters
                    oder nach Zweckfortfall aus der Newsletterverteilerliste gelöscht. Wir behalten
                    uns vor, E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen
                    im Rahmen unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO zu
                    löschen oder zu sperren.
                </p>
                <p>
                    Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse
                    bei uns bzw. dem Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert,
                    um künftige Mailings zu verhindern. Die Daten aus der Blacklist werden nur für
                    diesen Zweck verwendet und nicht mit anderen Daten zusammengeführt. Dies dient
                    sowohl Ihrem Interesse als auch unserem Interesse an der Einhaltung der
                    gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse im
                    Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist
                    zeitlich nicht befristet. Sie können der Speicherung widersprechen, sofern Ihre
                    Interessen unser berechtigtes Interesse überwiegen.
                </p>
            </section>
            <!-- SIXTH PART -->
            <!--
            <p style="margin: 50px 0 5px 0; font-size: 20px;">
                <strong>6. Plugins und Tools</strong>
            </p>

            <p style="margin: 15px 0 5px 0; font-size: 18px;">
                <strong>Google reCAPTCHA</strong>
            </p>
            <p>
                Wir nutzen „Google reCAPTCHA“ (im Folgenden „reCAPTCHA“) auf dieser Website. Anbieter ist die Google
                Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
                <br>
                Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf dieser Website (z. B. in einem
                Kontaktformular) durch einen Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu
                analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese
                Analyse beginnt automatisch, sobald der Websitebesucher die Website betritt. Zur Analyse wertet
                reCAPTCHA verschiedene Informationen aus (z. B. IP-Adresse, Verweildauer des Websitebesuchers auf
                der Website oder vom Nutzer getätigte Mausbewegungen). Die bei der Analyse erfassten Daten werden
                an Google weitergeleitet.
                <br>
                Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher werden nicht darauf
                hingewiesen, dass eine Analyse stattfindet.
                <br>
                Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                Websitebetreiber hat ein berechtigtes Interesse daran, seine Webangebote vor missbräuchlicher
                automatisierter Ausspähung und vor SPAM zu schützen. Sofern eine entsprechende Einwilligung abgefragt
                wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
                Einwilligung ist jederzeit widerrufbar.
                <br>
                Weitere Informationen zu Google reCAPTCHA entnehmen Sie den Google-Datenschutzbestimmungen und den
                Google Nutzungsbedingungen unter folgenden Links: https://policies.google.com/privacy?hl=de und
                https://policies.google.com/terms?hl=de.
            </p>
            -->
            <p class="mt-4 text-sm">Quelle: Datenschutz-Konfigurator der eRecht24 GmbH & Co. KG</p>
        </div>
    </ContentLayout>
</template>

<script>
import PageHeadline from '@/components/Layout/PageHeadline'
import ContentLayout from '@/components/Layout/ContentLayout'
import ContentH2 from '@/components/Layout/ContentH2'
import ContentH3 from '@/components/Layout/ContentH3'

export default {
    name: 'Datenschutz',
    meta() {
        return {
            title: 'Datenschutz',
        }
    },

    components: {
        ContentH3,
        ContentH2,
        ContentLayout,
        PageHeadline,
    },
}
</script>
<style></style>
