<template>
    <!--Details Container-->
    <ContentLayout>
        <PageHeadline>Impressum</PageHeadline>
        <div class="max-w-2xl text-left text-gray7">
            <ContentH2>Angaben gem&auml;&szlig; &sect; 5 TMG</ContentH2>
            <ul class="mt-6">
                <li>NovaNode GmbH i.G.</li>
                <li>Dresdnerstraße 44</li>
                <li>63110 Rodgau</li>
            </ul>
            <br />
            <ContentH3>Kontakt:</ContentH3>
            <ul>
                <li>Joel Daon</li>
                <li>
                    Telefon:
                    <a class="hover:text-primary" href="tel:+49610674410"> +49 (0) 6106 74410</a>
                </li>
                <li>
                    E-Mail:
                    <a class="hover:text-primary" href="mailto:info@cybo.de">info@cybo.de</a>
                </li>
            </ul>
            <br />
            <ContentH3>EU-Streitschlichtung</ContentH3>
            <p>
                Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung
                (OS) bereit:
                <a
                    href="https://ec.europa.eu/consumers/odr"
                    target="_blank"
                    rel="noopener noreferrer"
                    >https://ec.europa.eu/consumers/odr</a
                >. Unsere E-Mail-Adresse finden Sie oben im Impressum.
            </p>
            <ContentH3
                >Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</ContentH3
            >
            <p>
                Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                Verbraucherschlichtungsstelle teilzunehmen.
            </p>
            <ContentH3>Haftung f&uuml;r Inhalte</ContentH3>
            <p>
                Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r eigene
                Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
                &sect;&sect; 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
                &uuml;bermittelte oder gespeicherte fremde Informationen zu &uuml;berwachen oder
                nach Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit
                hinweisen.
            </p>
            <p>
                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
                allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche
                Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von entsprechenden
                Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>

            <br />
            <ContentH3>Haftung f&uuml;r Links</ContentH3>
            <p>
                Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf deren Inhalte wir
                keinen Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r diese fremden Inhalte auch
                keine Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist
                stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
                verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche
            </p>
        </div>
    </ContentLayout>
</template>

<script>
import ContentLayout from '@/components/Layout/ContentLayout'
import PageHeadline from '@/components/Layout/PageHeadline'
import ContentH2 from '@/components/Layout/ContentH2'
import ContentH3 from '@/components/Layout/ContentH3'

export default {
    name: 'Impressum',
    components: { PageHeadline, ContentLayout, ContentH2, ContentH3 },
    meta() {
        return {
            title: 'Impressum',
        }
    },
}
</script>

<style></style>
